
.container-about{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: flex-start;
    
    animation:lightSpeedInLeft;
    animation-duration: 1s;
}


.interest{
    width: 340px;
    margin-top: 1%;
    height: 323px;
    /* border: 1px solid black; */
    box-sizing: border-box;
    padding: 1%;
    background-image: url('../../images/Content/about.png');
    background-size: cover;

    animation:lightSpeedInLeft;
    animation-duration: 1s;
}

.interest-1{
    width: 340px;
    margin-top: 1%;
    height: 323px;
    margin-left: 190px;
    /* border: 1px solid black; */
    box-sizing: border-box;
    padding: 2%;
    background-image: url('../../images/Content/about.png');
    background-size: cover;
}

.text{
    font-size: 10px;
word-wrap: break-word;
padding: 10px;    
}

#avatarAbout{
    margin-top: 294px;
    width: 189px;
    height: auto;
    margin-right: 590px;
}

.btn-mobile{
    position: absolute;
    margin-left: 414px;
    margin-top: 261px;
    animation:lightSpeedInLeft;
    animation-duration: 1s;

}


.l-an{
    animation:lightSpeedInLeft;
    animation-duration: 1s;
}

/* @keyframes escribir{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }

}

.animacion-escribir{
    animation: escribir 4s ease-in-out;
} */

/* Media query para dispositivos móviles */
@media screen and (max-width: 768px) {
    .container-about {
        flex-direction: column; /* Cambia a un diseño de columna en dispositivos móviles */
        align-items: center; /* Centra los elementos en dispositivos móviles */   
        animation:lightSpeedInLeft;
        animation-duration: 1s;
    }

    .interest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 453px;
    height: 457px;
    box-sizing: border-box;
    padding: 1%;
        background-image: url('../../images/Content/about-mobile.png');
        background-size: cover;
    
        animation:lightSpeedInLeft;
        animation-duration: 1s;


    }

    .interest-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 200px;
        width: 453px;
        height: 457px;
        box-sizing: border-box;
        padding: 1%;
            background-image: url('../../images/Content/about-mobile.png');
            background-size: cover;
    
    
        }

    .btn-mobile{
        margin-left: 265px;
        animation:lightSpeedInLeft;
        animation-duration: 1s;

    }
    .text {
        width: 70%;
        margin-left: 80px;
        margin-top: 50px;
        padding-right: 65px;
    }

    .avatar{
    margin-left: -150px;
    margin-top: 10px;
    width: 189px;
    height: auto;
    }

    #avatarAbout {
        margin-top: 704px;
        width: 279px;
        height: auto;
        margin-right: 547px;
    }
}

@media screen and (max-width: 432px) {
    .container-about {
        flex-direction: column; /* Cambia a un diseño de columna en dispositivos móviles */
        align-items: center; /* Centra los elementos en dispositivos móviles */   
        animation:lightSpeedInLeft;
        animation-duration: 1s;
    }

    .interest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 453px;
    height: 457px;
    box-sizing: border-box;
    padding: 1%;
        background-image: url('../../images/Content/about-mobile.png');
        background-size: cover;
    
        animation:lightSpeedInLeft;
        animation-duration: 1s;


    }

    .interest-1 {
        display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0rem;
    margin-left: -2rem;
    width: 43rem;
    height: 457px;
        box-sizing: border-box;
        padding: 1%;
            background-image: url('../../images/Content/about-mobile.png');
            background-size: cover;
    
    
        }

    
        .btn-mobile{
            /* margin-left: 265px;
            animation:lightSpeedInLeft;
            animation-duration: 1s; */
            position: relative;
    left: 0rem;
    top: -38rem;
    animation: lightSpeedInLeft;
    animation-duration: 1s;
    
        }
    .text {
        /* width: 70%;
        margin-left: 80px;
        margin-top: 50px;
        padding-right: 65px; */
        /* width: 48%; */
        height: 34rem;
        margin-left: 9rem;
        margin-top: 4rem;
        padding-right: 2rem;
    }

    #avatarAbout {
        /* margin-top: 704px;
        width: 279px;
        height: auto;
        margin-right: 547px; */
        position: relative;
        top: -100rem;
        width: 24rem;
        height: auto;
        right: 0rem;
    }
}


@media screen and (max-width: 390px) {
    .container-about {
        flex-direction: column; /* Cambia a un diseño de columna en dispositivos móviles */
        align-items: center; /* Centra los elementos en dispositivos móviles */   
        animation:lightSpeedInLeft;
        animation-duration: 1s;
    }

    .interest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 453px;
    height: 457px;
    box-sizing: border-box;
    padding: 1%;
        background-image: url('../../images/Content/about-mobile.png');
        background-size: cover;
    
        animation:lightSpeedInLeft;
        animation-duration: 1s;


    }

    .interest-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 2rem;
        width: 43rem;
        height: 457px;
        box-sizing: border-box;
        padding: 1%;
            background-image: url('../../images/Content/about-mobile.png');
            background-size: cover;
    
    
        }

    .btn-mobile{
        /* margin-left: 265px;
        animation:lightSpeedInLeft;
        animation-duration: 1s; */
        margin-left: 23rem;
        margin-top: 26rem;
        animation: lightSpeedInLeft;
        animation-duration: 1s;

    }
    .text {
        /* width: 70%;
        margin-left: 80px;
        margin-top: 50px;
        padding-right: 65px; */
        /* width: 48%; */
        height: 34rem;
        margin-left: 9rem;
        margin-top: 4rem;
        padding-right: 2rem;
    }

    #avatarAbout {
        /* margin-top: 704px;
        width: 279px;
        height: auto;
        margin-right: 547px; */
        position: relative;
        margin-top: 68rem;
        width: 20rem;
        height: auto;
        margin-right: 56rem;
    }
}

@media screen and (max-width: 360px) {
    .container-about {
        flex-direction: column; /* Cambia a un diseño de columna en dispositivos móviles */
        align-items: center; /* Centra los elementos en dispositivos móviles */   
        animation:lightSpeedInLeft;
        animation-duration: 1s;
    }

    .interest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 453px;
    height: 457px;
    box-sizing: border-box;
    padding: 1%;
        background-image: url('../../images/Content/about-mobile.png');
        background-size: cover;
    
        animation:lightSpeedInLeft;
        animation-duration: 1s;


    }

    .interest-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: -1rem;
        width: 37rem;
        height: 396px;
        box-sizing: border-box;
        padding: 1%;
            background-image: url('../../images/Content/about-mobile.png');
            background-size: cover;
    
    
        }

    .btn-mobile{
        /* margin-left: 265px;
        animation:lightSpeedInLeft;
        animation-duration: 1s; */
        margin-left: 21rem;
        margin-top: 21rem;
        animation: lightSpeedInLeft;
        animation-duration: 1s;

    }
    .text {
        /* width: 70%;
        margin-left: 80px;
        margin-top: 50px;
        padding-right: 65px; */
        /* width: 48%; */
        width: 80%;
    height: 16rem;
    margin-left: 6rem;
    margin-top: 2rem;
    padding-right: 2rem;
    }


    #avatarAbout {
        /* margin-top: 704px;
        width: 279px;
        height: auto;
        margin-right: 547px; */
        margin-top: 65rem;
    width: 16rem;
    height: auto;
    margin-right: 54rem;
    }
}