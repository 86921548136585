.skill-container {
    display: flex;
    flex-direction: row;
    align-content: space-around;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 90px;
}

.skills {
  width: 100%; /* Cambiado a porcentaje para que sea relativo al contenedor */
  max-width: 425px; /* Establecido un máximo para evitar que sea demasiado ancho en pantallas grandes */
  height: 336px;
  border: solid 1px black;
  margin-top: 20px;
  background-image: url("../../images/Content/back.png");
  background-size: cover;
  box-sizing: border-box;
  padding: 108px 34px 30px;
}

.skill-animation{
    animation: balanceo1 2s alternate 2;
    transform-origin: top center; /* Punto de origen para la rotación */ 
    transform: rotate(0deg);

}
.skill-animation-1{
    animation: balanceo 2s alternate 2;
    transform-origin: top center; /* Punto de origen para la rotación */ 
    transform: rotate(0deg);

}

 @keyframes balanceo1 {
    from {
      transform: rotate(5deg);
    }
    to {
      transform: rotate(-8deg);
    }
  }



@keyframes balanceo {
    from {
      transform: rotate(-5deg);
    }
    to {
      transform: rotate(5deg);
    }
  }

.skills:hover{
    animation: pulse;
    animation-duration: 2s;
}
.title{
    text-align: center;
}


.tv-container{
  display: flex;
  position: relative;
  margin-top: 5px;
}

.tv{
  background-image: url("../../images/Content/tv.png");
  background-size: cover;
  width: 446px;
    height: 381px;
    margin-left: 545px;
}

.btn-tv{
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(386px, -35px);
  width: 56px;
  height: 56px;
  border-radius: 50px;
  
}

@keyframes prueba{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(45deg);
  }
}

.img-btn-tv{
  width: 60px;
  height: auto;
  left: -2px;
  top: -3px;
  position: absolute;
  
}
.img-btn-tv:hover{
  filter: drop-shadow(2px 1px 0px gold);
 
}

.vhs1:hover{
  cursor: pointer;
  filter: drop-shadow(2px 2px 15px gold);
}



.vhs2:hover{
  cursor: pointer;
  filter: drop-shadow(2px 2px 15px gold);
}
.img-btn-tv:active{
  animation-duration: 1s;
  animation-name: prueba;  
}

.img-avatar{
  position: absolute;
    width: 200px;
    height: 206px;
    top: 275px;
    left: 220px;
}

.content-tv{
    position: absolute;
 
    margin-left: 95px;
    margin-top: 108px;
    width: 222px;
    height: 158px;
    /* border: solid; */
    background-color: grey;
    border-radius: 18px;
}

.h3-tv {
  text-align: center;
  padding: 62px;
  opacity: 1;
  /* transform: translateX(100%);
  animation: aparecer 1s forwards; */
}
.h3-tv1 {
  text-align: center;
  padding: 8px;
  font-size: 16px;
}


/* Cambio de canal mediante ids */
#tv-3{
  padding: 30px;
  padding-top: 60px;
}

#tv-4{
  font-size: 16px;
  padding: 30px;
  padding-top: 60px;
}


#tv-5{
  font-size: 11px;
  padding: 30px;
  padding-top: 60px;
}

#tv-6 {
  padding: 30px;
  padding-top: 20px;
  font-size: 16px;
}


#tv1{
  background-color: #E44D26;
  color: linear-gradient(to right, #E44D26, #264DE4);
}


#tv2{
  background-color: #264DE4;
}

#tv3{

  background-color: #7952B3;
}

#tv4{
  background-color: #F0DB4F;
}

#tv5{
  background-color: #61DAFB;
}

#tv6{
  background-color: #68A063;
}

#tv7{
  background-color: #181717;
  color: white;
}

#ss1,#ss2,#ss3,#ss4,#ss5,#ss6,#ss7,#ss8,#ss9,#ss10,#ss11{
  background-color: #a4d4a0;
}







@keyframes aparecer{
  to{
    opacity: 1;
    transform: translateX(0);
  }
}

.video{
  position: absolute;
    left: 62%;
    width: 185px;
    top: 4%;
    height: fit-content;
}

.vhs1{
    top: 140px;
    left: 264px;
    transform: rotate(11deg);
    position: absolute;
    width: 210px;
    height: fit-content;
}

.vhs2{
  top: 140px;
      transform: rotate(349deg);
    position: absolute;
    width: 210px;
    height: fit-content;
}
.vhs-container{
  position: absolute;
  height: 317px;
  width: 510px;
  left: 275px;
  z-index: 1;
}

.casette{ 
  top: 32px;
  background: beige;
  left: 633px;
  width: 94px;
  text-align: center;
  padding: 4px;
  font-size: 8px;
  height: 21px;
  position: absolute; 
}


@media screen and (max-width: 768px){

  .vhs-container {
    position: absolute;
    height: 300px;
    width: 462px;
    left: 6px;
    z-index: 1;
}

.vhs2, .vhs1{
  width: 160px;
}

}

@media screen and (max-width: 1024px){

  .vhs-container {
    position: absolute;
    height: 300px;
    width: 462px;
    left: 68px;
    z-index: 1;
}

.vhs2, .vhs1{
  width: 227px;
}

}



/* Para iphone */
@media screen and (max-width: 432px){

footer{
  position: relative;
}

  .img-avatar {
    position: absolute;
    width: 176px;
    height: auto;
    top: 502px;
    left: 30px;
}


  .vhs-container {
      position: relative;
      height: 1rem;
      width: 44rem;
      left: 0rem;
      z-index: 1;
}

.vhs1{
  /* width: 181px;
  top: 58px;
  left: 206px; */
  position: relative;
  width: 18rem;
  height: auto;
  top: 6rem;
  left: 21rem;
}

.vhs2{
  /* width: 181px;
  top: 58px; */
  position: relative;
  width: 18rem;
  height: auto;
    top: 6rem;
    left: -17rem;
}

.skill-container {
  width: 505px;
  height: 700px;
}

.video {
  position: relative;
  left: 7rem;
  width: 18rem;
  top: 14.5rem;
  height: auto;
}

.tv{
 /*  margin-left: 0px;
  margin-top: 78px; */
  width: 42rem;
    margin-left: 0rem;
    margin-top: 8rem;
}

.casette {
  top: 2.8rem;
  background: beige;
  left: 8.7rem;
  width: 94px;
  text-align: center;
  padding: 4px;
  font-size: 8px;
  height: 21px;
  position: relative;
}

.img-btn-tv {
  width: 56px;
  height: auto;
  left: 0px;
  top: 0px;
  position: absolute;
}
.btn-tv {
  /* border: none;
  position: absolute;
  top: 59%;
  left: -11%;
  transform: translate(386px, -35px);
  width: 56px;
  height: 56px;
  border-radius: 50px; */
  border: none;
    position: absolute;
    top: 31rem;
    left: -6.8rem;
    transform: translate(386px, -35px);
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50px;
}

.content-tv {
  position: relative;
  margin-left: 8.4rem;
  margin-top: 8rem;
  width: 21.8rem;
  height: 16rem;
  z-index: -1;
  /* border: solid; */
  background-color: grey;
  border-radius: 18px;
}

.tv-container {
  display: flow;
  position: relative;
  margin-top: 5px;
}

}


@media screen and (max-width: 390px){


  .tv-container {
    /* display: flex; */
    width: 42rem;
    margin-right: 4rem;
    position: relative;
    margin-top: 5px;
}


  footer{
    position: relative;
  }
  
    .img-avatar {
      position: absolute;
      width: 176px;
      height: auto;
      top: 472px;
      left: 30px;
  }
  
  
    .vhs-container {
      /* position: absolute;
      height: 300px;
      width: 390px;
      left: 15px;
      z-index: 1; */
      position: absolute;
      height: 18rem;
      width: 37rem;
      left: 0px;
      z-index: 1;
  }
  
  .vhs1{
    /* width: 181px;
    top: 58px;
    left: 206px; */
    width: 16rem;
    height: auto;
    top: 6rem;
    left: 21rem;
  }
  
  .vhs2{
    /* width: 181px;
    top: 58px; */
    height: auto;
    width: 16rem;
      top: 6rem;
      left: -13rem;
  }
  
  .skill-container {
    width: 505px;
    height: 700px;
  }
  
  .video {
    position: relative;
    left: 17%;
    width: 185px;
    top: 29%;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .tv{
   /*  margin-left: 0px;
    margin-top: 78px; */
    width: 42rem;
    margin-left: 0rem;
    margin-top: 8rem;
  }
  
  .casette {
    top: 166px;
    background: beige;
    left: 90px;
    width: 94px;
    text-align: center;
    padding: 4px;
    font-size: 8px;
    height: 21px;
    position: absolute;
  }
  
  .img-btn-tv {
    width: 56px;
    height: auto;
    left: 0px;
    top: 0px;
    position: absolute;
  }
  .btn-tv {
    /* border: none;
    position: absolute;
    top: 59%;
    left: -11%;
    transform: translate(386px, -35px);
    width: 56px;
    height: 56px;
    border-radius: 50px; */
    border: none;
    position: absolute;
    top: 31rem;
    left: -7rem;
    transform: translate(386px, -35px);
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50px;
  }
  
  .content-tv {
    position: absolute;
    margin-left: 8.4rem;
    margin-top: 10rem;
    width: 21.8rem;
    height: 16rem;
    z-index: -1;
    /* border: solid; */
    background-color: grey;
    border-radius: 18px;
  }
  
  }
  

  @media screen and (max-width: 360px){


    .tv-container {
      /* display: flex; */
      width: 37rem;
      margin-right: 4rem;
      position: relative;
      margin-top: 5px;
  }
  
  
    footer{
      position: relative;
    }
    
      .img-avatar {
        position: absolute;
        width: 176px;
        height: auto;
        top: 472px;
        left: 30px;
    }
    
    
      .vhs-container {
        /* position: absolute;
        height: 300px;
        width: 390px;
        left: 15px;
        z-index: 1; */
        position: absolute;
        height: 18rem;
        width: 36rem;
        left: 0px;
        z-index: 1;
    }
    
    .vhs1{
      /* width: 181px;
      top: 58px;
      left: 206px; */
      width: 14rem;
      height: auto;
      top: 6rem;
      left: 19rem;
    }
    
    .vhs2{
      /* width: 181px;
      top: 58px; */
      width: 14rem;
      height: auto;
        top: 6rem;
        left: -10rem;
    }
    
    .skill-container {
      width: 505px;
      height: 700px;
    }
    
    .video {
      position: relative;
      left: 17%;
      width: 162px;
      top: 28%;
      height: -moz-fit-content;
      height: fit-content;
    }
    
    .tv{
     /*  margin-left: 0px;
      margin-top: 78px; */
      width: 39rem;
      margin-left: 0rem;
      margin-top: 8rem;
    }
    
    .casette {
      top: 156px;
    background: beige;
    left: 79px;
    width: 82px;
    text-align: center;
    padding: 4px;
    font-size: 8px;
    height: 21px;
    position: absolute;
    }
    
    .img-btn-tv {
      width: 45px;
      height: auto;
      left: 0px;
      top: 0px;
      position: absolute;
    }
    .btn-tv {
      /* border: none;
      position: absolute;
      top: 59%;
      left: -11%;
      transform: translate(386px, -35px);
      width: 56px;
      height: 56px;
      border-radius: 50px; */
      border: none;
      position: absolute;
      top: 30rem;
      left: -9rem;
      transform: translate(386px, -35px);
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50px;
    }
    
    .content-tv {
      position: absolute;
      margin-left: 6.4rem;
      margin-top: 9.4rem;
      width: 22.9rem;
      height: 14rem;
      z-index: -1;
      /* border: solid; */
      background-color: grey;
      border-radius: 18px;
    }
    
    #tv-5{
      font-size: 11px;
      padding: 30px;
      padding-top: 60px;
    }
    
    #tv-6 {
      padding: 30px;
      padding-top: 20px;
      font-size: 13px;
    }

    }
    