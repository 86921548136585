.container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container1{
    width: 100%;
}

.form-container{
    width: 308px;
    padding-top: 92px;
}


.formcon {
    position: relative;
    width: 234px;
    max-width: 408px;
    margin-left: 579px;
    margin-top: -79px;
}

.form-img {
    top: 69px;
    left: 496px;
    z-index: -1;
    position: absolute;
    width: 362px;
    height: auto;
}

.pencil{
    top: 374px;
    right: 779px;
    z-index: -1;
    position: absolute;
    width: 166px;
    height: auto;
}

.mug{
    top: 400px;
    right: 401px;
    z-index: 0;
    position: absolute;
    width: 190px;
    height: auto;
    transform: rotate(11deg);
}

.mesa{
    top: 57px;
    right: 218px;
    z-index: -1;
    position: absolute;
    width: 924px;
    height: auto;
}

.icons{
    width: 68px;
    height: auto;
}


#in{
    position: relative;
    RIGHT: -331px;
    top: 36px;
    transform: rotate(342deg);
}

#git{
    position: relative;
    RIGHT: -271px;
    top: 334px;
    transform: rotate(24deg);
}

#cv{
    position: relative;
    RIGHT: -198px;
    top: 189px;
    transform: rotate(342deg);
}

#in:hover{
    cursor: pointer;
    filter: drop-shadow(2px 1px 0px gold);
}

#git:hover{
    cursor: pointer;
    filter: drop-shadow(2px 1px 0px gold);
}

#cv:hover{
    cursor: pointer;
    filter: drop-shadow(2px 1px 0px gold);
}

form {
    max-width: 400px;
    margin: 20px auto;
}

label {
    display: block;
    margin-bottom: 8px;
    margin-top: 10px;
}

input, textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

input, textarea{
    background: transparent;
    border: none;
    border-bottom: 1px solid black;
}


textarea {
    resize: vertical;
}



@media screen and (max-width: 1024px){


.formcon {
    position: relative;
    width: 234px;
    max-width: 408px;
    margin-left: 404px;
    margin-top: -79px;
}
    
    .mesa{
        top: 130px;
        right: 50px;
    }
    
    .mug{
        top: 461px;
        right: 267px;
    }
    
    .form-img {
        top: 139px;
        left: 338px;
        z-index: -1;
        position: absolute;
        width: 362px;
        height: auto;
    }
    
    .pencil{
        top: 428px;
        right: 591px;
    }
    
    .form-container{
        padding-top: 160px;
    }

    #in {
        position: relative;
        RIGHT: -178px;
        top: 111px;
        transform: rotate(342deg);
    }

    #cv {
        position: relative;
        RIGHT: -45px;
        top: 244px;
        transform: rotate(342deg);
    }

    #git {
        position: relative;
        RIGHT: -121px;
        top: 418px;
        transform: rotate(24deg);
    }


    }
    


@media screen and (max-width: 768px){
    .mug {
        top: 490px;
        right: 95px;
        z-index: 0;
        position: absolute;
        width: 190px;
        height: auto;
        transform: rotate(11deg);
    }
    .pencil {
        top: 450px;
        right: 457px;
        z-index: -1;
        position: absolute;
        width: 166px;
        height: auto;
    }

    .mesa {
    top: 121px;
    right: -23px;
    z-index: -1;
    position: absolute;
    width: 788px;
    height: 526px;
}

    .form-img {
        top: 148px;
        z-index: -1;
        position: absolute;
        width: 362px;
        height: auto;
    }
    .form-container {
        margin-top: 0px;
        max-width: 400px;
    }
    #fot {
        position: fixed;
    }
}


@media screen and (max-width: 432px){
    .mug {
        top: 20rem;
        right: -1rem;
        z-index: 0;
        position: relative;
        width: 168px;
        height: auto;
        transform: rotate(11deg);
    
    }
    .pencil {
        top: -40rem;
        left: 0rem;
        z-index: -1;
        position: relative;
        width: 166px;
        height: auto;
    }

    .mesa {
        top: 22rem;
    right: 18rem;
    z-index: -1;
    position: relative;
    width: 743px;
    height: auto;
    transform: rotate(90deg);
    }

    .form-img {
        left: 1rem;
    top: -29rem;
    z-index: -1;
    position: relative;
    width: 362px;
    height: auto;
    }
    .formcon {
        position: relative;
        margin-top: -96rem;
        max-width: 400px;
        margin-left: 8rem;
    }


    #in{
        position: relative;
        RIGHT: -4rem;
        top: -29rem;
        transform: rotate(342deg);
    }
    
    #git{
        position: relative;
        RIGHT: -22rem;
        top: -29rem;
        transform: rotate(24deg);
    }
    
    #cv{
        position: relative;
        RIGHT: -3rem;
        top: -29rem;
        transform: rotate(0deg);
    }
    


    #fot {
      top: 262px;
        position: fixed;
    }
}


@media screen and (max-width: 390px){
    .mug {
        top: 20rem;
        right: -1rem;
        z-index: 0;
        position: relative;
        width: 168px;
        height: auto;
        transform: rotate(11deg);
    
    }
    .pencil {
        top: -40rem;
        left: 0rem;
        z-index: -1;
        position: relative;
        width: 166px;
        height: auto;
    }

    .mesa {
        top: 22rem;
    right: 18rem;
    z-index: -1;
    position: relative;
    width: 743px;
    height: auto;
    transform: rotate(90deg);
    }

    .form-img {
        left: 1rem;
    top: -29rem;
    z-index: -1;
    position: relative;
    width: 362px;
    height: auto;
    }
    .formcon {
        position: relative;
        margin-top: -96rem;
        max-width: 400px;
        margin-left: 8rem;
    }


    #in{
        position: relative;
        RIGHT: -4rem;
        top: -29rem;
        transform: rotate(342deg);
    }
    
    #git{
        position: relative;
        RIGHT: -22rem;
        top: -29rem;
        transform: rotate(24deg);
    }
    
    #cv{
        position: relative;
        RIGHT: -3rem;
        top: -29rem;
        transform: rotate(0deg);
    }
    


    #fot {
      top: 262px;
        position: fixed;
    }
}

@media screen and (max-width: 371px){
    .mug {
        top: 12rem;
        right: -20rem;
        z-index: 0;
        position: relative;
        width: 168px;
        height: auto;
        transform: rotate(11deg);
    
    }
    .pencil {
        top: -50rem;
        left: 0rem;
        z-index: -1;
        position: relative;
        width: 166px;
        height: auto;
    }

    .mesa {
        top: 22rem;
    right: 19rem;
    z-index: -1;
    position: relative;
    width: 727px;
    height: auto;
    transform: rotate(90deg);
    }

    .form-img {
        left: 1rem;
    top: -38rem;
    z-index: -1;
    position: relative;
    width: 362px;
    height: auto;
    }
    .formcon {
        position: relative;
        margin-top: -109rem;
        max-width: 400px;
        margin-left: 8rem;
    }


    #in{
        position: relative;
        RIGHT: -4rem;
        top: -29rem;
        transform: rotate(342deg);
    }
    
    #git{
        position: relative;
        RIGHT: -22rem;
        top: -29rem;
        transform: rotate(24deg);
    }
    
    #cv{
        position: relative;
        RIGHT: -3rem;
        top: -29rem;
        transform: rotate(0deg);
    }
    


    #fot {
      top: 262px;
        position: fixed;
    }
}
