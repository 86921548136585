/* Loader.css */

.loader-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 10%
  }
  
  .loader {
    position: relative;
    width: 100px; /* Ajusta el tamaño según tus necesidades */
    height: 100px;
  }
  
  .image1,
  .image2 {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
  }
  
  .image2 {
    opacity: 0;
  }
  