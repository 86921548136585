.avatar {
    margin-top: 301px;
    width: 187px;
    height: auto;
    position: absolute;
    margin-left: 340px;
}

.board{
    right: 395px;
    top: 68px;
    position: absolute;
    width: 560px;
}

.book{
    width: 135px;
    position: absolute;
}


.op {
    width: 1px;
}

.open-book {
    width: 230px;
    height: auto;
    position: relative;
    z-index: 2;
    top: 0px;
    right: -302px;
    filter: drop-shadow(4px 0px 3px gold);
}

#book-1{
    right: 824px;
    top: 342px;
    z-index: 3;
}

#book-2{
    right: 831px;
    top: 308px;
    z-index: 3;
}

#book-3{
    right: 835px;
    top: 275px;
    z-index: 3;
}

#book-1:hover{
cursor: pointer;
filter: drop-shadow(2px 1px 1px gold);
}

#book-2:hover{
    cursor: pointer;
    filter: drop-shadow(2px 1px 1px gold);
}
#book-3:hover{
    cursor: pointer;
    filter: drop-shadow(2px 1px 1px gold);
}

#repo1{
    font-size: 7px;
    position: relative;
    left: 160px;
    top: 73px;
    z-index: 3;
}

#deploy1{
    font-size: 7px;
    position: relative;
    left: 262px;
    top: 73px;
    z-index: 3;
}

#deploy2 {
    font-size: 7px;
    position: relative;
    left: 431px;
    top: 43px;
    z-index: 3;
}

#repo2 {
    font-size: 7px;
    position: relative;
    left: 262px;
    top: 81px;
    z-index: 3;
}
.container-project{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.content-book{
    position: absolute;
    width: 405px;
    z-index: 0;
    top: 96px;
    height: 175px;
    right: 470px;
}

.h3-book{
    color: whitesmoke;
    text-align: center;
    padding-top: 19px;
 
}

@media screen and (max-width: 768px){
    
    .board {
        right: 100px;
        top: 12px;
        position: relative;
        width: 560px;
    }

    #book-1 {
        position: relative;
        right: 525px;
        top: -189px;
    }

    #book-2 {
        position: relative;
        right: 517px;
        top: -260px;
    }

    #book-3 {
        position: relative;
        right: 521px;
        top: -329px;
    }


#avt{
    display: none;
}
.content-book {
    position: relative;
    width: 405px;
    z-index: 0;
    top: -525px;
    height: 175px;
    right: 180px;
}

}


@media screen and (max-width: 432px){
    .board {
        right: 33px;
        top: 68px;
        position: relative;
        width: 360px;
    }

#book-3 {
    position: relative;
    width: 65px;
    right: 328px;
    top: -122px;
    z-index: 1;
}

#book-2 {
    position: relative;
    width: 65px;
    right: 334px;
    top: -89px;
    z-index: 1;
}

#book-1 {
    position: relative;
    width: 65px;
    right: 325px;
    top: -55px;
    z-index: 1;
}


.h3-book {
    color: whitesmoke;
    text-align: center;
    padding-top: 0px;
    margin-left: 0px;
    font-size: 10px;
}

#avt{
    display: none;
}

#fot {
    display: none;
    position: relative;
}
.content-book {
    position: relative;
    width: 252px;
    z-index: 0;
    top: -249px;
    height: 152px;
    right: 87px;
}
.open-book {
    width: 230px;
    height: auto;
    position: relative;
    z-index: 2;
    top: 106px;
    right: -58px;
    filter: drop-shadow(4px 0px 3px gold);
}

.ani{
    font-size: 1em;
    position: absolute;
    z-index: 3;
    left: 38px;
    top: 215px;
}


#ani1{
    font-size: 1em;
    position: relative;
    z-index: 3;
    left: 38px;
    top: 215px;

}


#deploy2 {
    font-size: 7px;
    position: relative;
    left: 188px;
    top: 143px;
    z-index: 3;
}

#deploy1 {
    font-size: 7px;
    position: relative;
    left: 98px;
    top: 168px;
    z-index: 3;
}
#repo2 {
    font-size: 7px;
    position: relative;
    left: 100px;
    top: 180px;
    z-index: 3;
}

#repo1 {
    font-size: 7px;
    position: relative;
    left: 38px;
    top: 163px;
    z-index: 3;
}


}


@media screen and (max-width: 392px){
    .board {
        right: 1rem;
        top: 6rem;
        position: relative;
        width: 360px;
    }

#book-3 {
    position: relative;
    width: 65px;
    right: 30rem;
    top: -126px;
    z-index: 1;
}

#book-2 {
    position: relative;
    width: 65px;
    right: 30rem;
    top: -93px;
    z-index: 1;
}

#book-1 {
    position: relative;
    width: 65px;
    right: 30rem;
    top: -6rem;
    z-index: 1;
}


.h3-book {
    color: whitesmoke;
    text-align: center;
    padding-top: 0px;
    margin-left: 0px;
    font-size: 10px;
}

#avt{
    display: none;
}

#fot {
    display: none;
    position: relative;
}
.content-book {
    position: relative;
    width: 21rem;
    z-index: 0;
    top: -249px;
    height: 152px;
    right: 87px;
}
.open-book {
    width: 230px;
    height: auto;
    position: relative;
    z-index: 2;
    top: 106px;
    right: -58px;
    filter: drop-shadow(4px 0px 3px gold);
}

.ani{
    font-size: 1em;
    position: absolute;
    z-index: 3;
    left: 38px;
    top: 215px;
}


#ani1{
    font-size: 1em;
    position: relative;
    z-index: 3;
    left: 38px;
    top: 215px;

}

#ani{
    font-size: 1em;
    position: relative;
    z-index: 3;
    left: 81px;
    top: 215px;

}


#deploy2 {
    font-size: 7px;
    position: relative;
    left: 188px;
    top: 143px;
    z-index: 3;
}

#deploy1 {
    font-size: 7px;
    position: relative;
    left: 117px;
    top: 168px;
    z-index: 3;
}
#repo2 {
    font-size: 7px;
    position: relative;
    left: 117px;
    top: 180px;
    z-index: 3;
}

#repo1 {
    font-size: 7px;
    position: relative;
    left: 60px;
    top: 163px;
    z-index: 3;
}
}


@media screen and (max-width: 361px){
    .board {
        right: 1rem;
        top: 6rem;
        position: relative;
        width: 338px;
    }

#book-3 {
    position: relative;
    width: 75px;
    right: 28rem;
    top: -135px;
    z-index: 1;
}

#book-2 {
    position: relative;
    width: 75px;
    right: 27rem;
    top: -97px;
    z-index: 1;
}

#book-1 {
    position: relative;
    width: 75px;
    right: 28rem;
    top: -59px;
    z-index: 1;
}


.h3-book {
    color: whitesmoke;
    text-align: center;
    padding-top: 0px;
    margin-left: 0px;
    font-size: 10px;
}

#avt{
    display: none;
}

#fot {
    display: none;
    position: relative;
}
.content-book {
    position: relative;
    width: 21rem;
    z-index: 0;
    top: -249px;
    height: 152px;
    right: 87px;
}
.open-book {
    width: 230px;
    height: auto;
    position: relative;
    z-index: 2;
    top: 106px;
    right: -58px;
    filter: drop-shadow(4px 0px 3px gold);
}

.ani{
    font-size: 1em;
    position: absolute;
    z-index: 3;
    left: 38px;
    top: 215px;
}


#ani1{
    font-size: 1em;
    position: relative;
    z-index: 3;
    left: 38px;
    top: 215px;

}

#ani{
    font-size: 1em;
    position: relative;
    z-index: 3;
    left: 81px;
    top: 215px;

}


#deploy2 {
    font-size: 7px;
    position: relative;
    left: 188px;
    top: 143px;
    z-index: 3;
}

#deploy1 {
    font-size: 7px;
    position: relative;
    left: 117px;
    top: 168px;
    z-index: 3;
}
#repo2 {
    font-size: 7px;
    position: relative;
    left: 117px;
    top: 180px;
    z-index: 3;
}

#repo1 {
    font-size: 7px;
    position: relative;
    left: 60px;
    top: 163px;
    z-index: 3;
}
}


